import { createSelector } from '@reduxjs/toolkit'
import {
  CheckoutOrderType,
  CheckoutStatus,
  OrderFormat,
  OrderFormatOption,
} from '@ancon/wildcat-types'
import getTotalCheckoutItemsQuantity from '@ancon/wildcat-utils/checkout/getTotalCheckoutItemsQuantity'
import getCheckoutPenguinLockersMetaData from '@ancon/wildcat-utils/checkout/getCheckoutPenguinLockersMetaData'
import isAOTippingEnabledForOrderFormat from '@ancon/wildcat-utils/outlet/isAOTippingEnabledForOrderFormat'
import getCheckoutGrandTotalAmount from '@ancon/wildcat-utils/checkout/getCheckoutGrandTotalAmount'
import isWoltDeliveryInProgress from '@ancon/wildcat-utils/checkout/isWoltDeliveryInProgress'
import {
  isTicketItemClosed,
  isTicketItemReady,
} from '@ancon/wildcat-utils/ticketItem'

import type { RootState } from '../../../store/types'
import { CheckoutReducerState, OrderSummaryStatusState } from '../types'
import {
  isPreOrderCheckoutSelector,
  preOrderAttendeeItemsByIdSelector,
  preOrderCheckoutAddItemPendingSelector,
  preOrderCheckoutCurrencySelector,
  preOrderCheckoutIsValidCheckoutSelector,
  preOrderCheckoutOutletIdSelector,
  preOrderCheckoutOutletNameSelector,
  preOrderCheckoutSelector,
  preOrderCheckoutTotalItemCountSelector,
  preOrderIsWaitingForPaymentSelector,
  preOrderSelectedAttendeeIdSelector,
  preOrderUserIdSelector,
} from '../../preOrder/store/preOrderSelectors'
import { outletSelectedOutletIdSelector } from '../../outlet/store/outletSelector'

function checkoutSelector<K extends keyof CheckoutReducerState>(
  state: RootState,
  key: K,
) {
  return state.checkout[key]
}

export const checkoutCurrentCheckoutOutletIdSelector = (state: RootState) =>
  checkoutSelector(state, 'currentCheckoutOutletId')

export const checkoutCurrentCheckoutDetailsSelector = (state: RootState) =>
  checkoutSelector(state, 'currentCheckoutDetails')

export const generalCheckoutAddItemPendingSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutAddItemPending')

export const checkoutIdSelector = (state: RootState) =>
  checkoutCurrentCheckoutDetailsSelector(state)?.id

export const checkoutFiltersSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutFilters')

export const checkoutFiltersOrderFormatSelector = (state: RootState) =>
  checkoutFiltersSelector(state).orderFormat

export const checkoutFiltersServiceTimeSelector = (state: RootState) =>
  checkoutFiltersSelector(state).serviceTime

export const checkoutFiltersDeliverySelector = (state: RootState) =>
  checkoutFiltersSelector(state)?.delivery

export const checkoutFiltersSectionSelector = (state: RootState) =>
  checkoutFiltersSelector(state)?.section

export const checkoutFiltersSectionIdSelector = (state: RootState) =>
  checkoutFiltersSelector(state)?.section?.id

export const checkoutItemsSelector = (state: RootState) =>
  checkoutCurrentCheckoutDetailsSelector(state)?.items || []

export const checkoutItemsCountSelector = (state: RootState) =>
  checkoutItemsSelector(state).length

export const checkoutCurrentCheckoutTotalAmountCurrencySelector = (
  state: RootState,
) => checkoutCurrentCheckoutDetailsSelector(state)?.totalAmount.currency

export const checkoutCurrentCheckoutDiscountSelector = (state: RootState) =>
  checkoutCurrentCheckoutDetailsSelector(state)?.discount

export const checkoutCurrentCheckoutTotalDiscountAmountSelector = (
  state: RootState,
) => checkoutCurrentCheckoutDetailsSelector(state)?.subTotalDiscountInclTax

export const checkoutCurrentCheckoutIdSelector = (state: RootState) =>
  checkoutSelector(state, 'currentCheckoutId')

export const checkoutUpdateItemPendingSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutUpdateItemPending')

export const checkoutUpdatePendingSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutUpdatePending')

export const checkoutCurrentCheckoutSelectedItemIdSelector = (
  state: RootState,
) => checkoutSelector(state, 'currentCheckoutSelectedCheckoutItemId')

export const checkoutOrderInstructionsSelector = (state: RootState) =>
  checkoutCurrentCheckoutDetailsSelector(state)?.instructions

export const generalCheckoutTotalItemQtySelector = createSelector(
  (state: RootState) => checkoutCurrentCheckoutDetailsSelector(state),
  checkout => getTotalCheckoutItemsQuantity(checkout?.items),
)

export const generalCheckoutOutletNameSelector = (state: RootState) =>
  checkoutCurrentCheckoutDetailsSelector(state)?.outlet.name

export const generalCheckoutCompanyIdSelector = (state: RootState) =>
  checkoutCurrentCheckoutDetailsSelector(state)?.company?.id ?? null

export const checkoutDeletePendingSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutDeletePending')

export const checkoutIsMinimumOrderModalVisibleSelector = (state: RootState) =>
  checkoutSelector(state, 'isMinimumOrderModalVisible')

export const checkoutIsDiscountModalVisibleSelector = (state: RootState) =>
  checkoutSelector(state, 'isDiscountModalVisible')

export const checkoutApplyDiscountPendingSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutApplyDiscountPending')

export const checkoutCurrentCheckoutOutletListItemSelector = (
  state: RootState,
) => checkoutSelector(state, 'currentCheckoutOutletListDetails')

export const checkoutIsVisibleCustomerInfoModalSelector = (state: RootState) =>
  checkoutSelector(state, 'isCustomerInfoModalVisible')

export const checkoutIsVisibleDeleteOrderModalSelector = (state: RootState) =>
  checkoutSelector(state, 'isVisibleDeleteOrderModal')

export const checkoutStockErrorAdditionalDataSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutStockErrorAdditionalData')

export const checkoutIsVisibleBoxPickupScheduleOrderModalSelector = (
  state: RootState,
) => checkoutSelector(state, 'isVisibleBoxPickupScheduleOrderModal')

export const checkoutCurrentCheckoutSubTotalSelector = (state: RootState) =>
  checkoutCurrentCheckoutDetailsSelector(state)?.subtotalInclTax

export const checkoutCartMinimumDifferenceAmountSelector = (state: RootState) =>
  checkoutCurrentCheckoutDetailsSelector(state)?.minimumOrderDifferenceAmount

export const checkoutItemsByVariantIdSelector = createSelector(
  [checkoutItemsSelector, (_, variantId: string) => variantId],
  (items, variantId) =>
    items.filter(item => item.product.variantId === variantId),
)

export const checkoutItemQuantityByVariantIdSelector = createSelector(
  (state: RootState, productId: string) =>
    checkoutItemsByVariantIdSelector(state, productId),
  checkoutItems => checkoutItems.reduce((acc, item) => acc + item.quantity, 0),
)

export const checkoutCurrentCheckoutDetailsFetchPendingSelector = (
  state: RootState,
) => checkoutSelector(state, 'currentCheckoutDetailsFetchPending')

export const checkoutCurrentCheckoutDetailsOrderFormatSelector = (
  state: RootState,
) => checkoutCurrentCheckoutDetailsSelector(state)?.orderFormat

export const checkoutCurrentCheckoutDetailsDeliveryAddressSelector = (
  state: RootState,
) => checkoutCurrentCheckoutDetailsSelector(state)?.delivery?.address

export const checkoutCurrentCheckoutDetailsCustomerUserIdSelector = (
  state: RootState,
) => checkoutCurrentCheckoutDetailsSelector(state)?.customer.userId

export const checkoutOutletSupportedOrderFormatsSelector = (state: RootState) =>
  checkoutCurrentCheckoutOutletListItemSelector(state)?.supportedOrderFormats

export const checkoutCurrentCheckoutDetailsOutletEnableCustomOrderNotesInAOSelector =
  (state: RootState) =>
    !!checkoutCurrentCheckoutOutletListItemSelector(state)
      ?.enableCustomOrderNotesInAO

export const checkoutCurrentCheckoutUpdatePendingSelector = (
  state: RootState,
) => checkoutSelector(state, 'checkoutUpdatePending')

export const checkoutOutletListItemFetchPendingSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutOutletListItemFetchPending')

export const checkoutValidatePendingSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutValidatePending')

export const checkoutIsInitialCurrentCheckoutPendingSelector = createSelector(
  [
    checkoutCurrentCheckoutDetailsSelector,
    checkoutCurrentCheckoutDetailsFetchPendingSelector,
    checkoutOutletListItemFetchPendingSelector,
  ],
  (checkoutDetails, checkoutDetailsFetchPending, outletListItemFetchPending) =>
    !checkoutDetails &&
    (checkoutDetailsFetchPending || outletListItemFetchPending),
)

export const checkoutCheckoutDetailsAvailableSelector = (state: RootState) =>
  !!checkoutCurrentCheckoutDetailsSelector(state)

export const checkoutCurrentCheckoutSelectedItemSelector = createSelector(
  [
    isPreOrderCheckoutSelector,
    checkoutCurrentCheckoutSelectedItemIdSelector,
    preOrderSelectedAttendeeIdSelector,
    state => state,
  ],
  (isPreOrder, selectedCheckoutItemId, selectedPreOrderAttendeeId, state) => {
    let items
    if (isPreOrder) {
      const preOrderUserId = preOrderUserIdSelector(state)
      const attendeeId = selectedPreOrderAttendeeId || preOrderUserId
      items = preOrderAttendeeItemsByIdSelector(state, attendeeId)
    } else {
      items = checkoutItemsSelector(state)
    }

    return items?.find(item => item.id === selectedCheckoutItemId)
  },
)

export const checkoutCurrentCheckoutPendingSelector = createSelector(
  [
    checkoutCurrentCheckoutDetailsFetchPendingSelector,
    generalCheckoutAddItemPendingSelector,
    checkoutDeletePendingSelector,
    checkoutUpdateItemPendingSelector,
    checkoutUpdatePendingSelector,
    checkoutValidatePendingSelector,
  ],
  (
    fetchDetailsPending,
    addItemPending,
    deleteCheckoutPending,
    updateItemPending,
    checkoutUpdatePending,
    checkoutValidatePending,
  ) =>
    fetchDetailsPending ||
    addItemPending ||
    deleteCheckoutPending ||
    updateItemPending ||
    checkoutUpdatePending ||
    checkoutValidatePending,
)

export const checkoutFiltersTableNumberSelector = (state: RootState) =>
  checkoutFiltersSelector(state)?.salesLocation?.table?.number

export const checkoutSummarySelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutSummary')

export const hasCheckoutSummarySelector = (state: RootState) =>
  !!checkoutSummarySelector(state)

export const checkoutSummaryCheckoutIdSelector = (state: RootState) =>
  checkoutSummarySelector(state)?.id

export const checkoutSummaryFetchPendingSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutSummaryFetchPending')

export const checkoutSummaryAuthorizedAtSelector = (state: RootState) =>
  checkoutSummarySelector(state)?.authorizedAt

export const checkoutSummaryOutletListItemSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutSummaryOutletListItem')

export const checkoutSummaryOutletIdSelector = (state: RootState) =>
  checkoutSummaryOutletListItemSelector(state)?.id

export const currentCheckoutCancelPendingSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutCancelPending')

export const checkoutSummaryCheckoutStatusSelector = (state: RootState) =>
  checkoutSummarySelector(state)?.status

export const checkoutSummaryCheckoutOrderFormatSelector = (state: RootState) =>
  checkoutSummarySelector(state)?.orderFormat

export const checkoutSummaryCancelInfoSelector = (state: RootState) =>
  checkoutSummarySelector(state)?.cancelInfo

export const checkoutSummaryCustomersFetchTableOrdersSelector = (
  state: RootState,
) => !!checkoutSummarySelector(state)?.outlet?.customersFetchTableOrders

export const checkoutSummaryCheckoutItemsSelector = (state: RootState) =>
  checkoutSummarySelector(state)?.items ?? []

export const checkoutSummaryReadyItemIdsSelector = (state: RootState) =>
  (checkoutSummarySelector(state)?.items || []).reduce(
    (acc: string[], item) => {
      if (item.ticketItem && isTicketItemReady(item.ticketItem)) {
        acc.push(item.ticketItem.ticketItemId)
      }
      return acc
    },
    [],
  )

export const checkoutSummaryPartiallyReadyItemCountSelector = (
  state: RootState,
) => checkoutSummaryReadyItemIdsSelector(state)?.length || 0

export const checkoutSummaryPartiallyDoneItemCountSelector = (
  state: RootState,
) =>
  checkoutSummarySelector(state)?.items?.filter(item =>
    isTicketItemClosed(item?.ticketItem),
  )?.length || 0

export const checkoutSummaryTotalItemCountSelector = (state: RootState) =>
  checkoutSummaryCheckoutItemsSelector(state).length

export const checkoutSummaryCheckoutServiceTimeSelector = (state: RootState) =>
  checkoutSummarySelector(state)?.serviceTime

export const checkoutSummaryCheckoutPreparationTimeSelector = (
  state: RootState,
) => checkoutSummarySelector(state)?.tickets[0]?.preparationTime

export const checkoutSummaryOutletEnablePartiallyPrepareOrdersSelector =
  createSelector(
    [checkoutSummaryOutletListItemSelector],
    outlet => !!outlet?.enablePartiallyPrepareOrders,
  )

export const checkoutWayOfPaymentsFetchPendingSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutWayOfPaymentsFetchPending')

export const currentCheckoutPaymentPendingSelector = (state: RootState) =>
  checkoutSelector(state, 'currentCheckoutPaymentPending')

export const checkoutSummaryTicketIdSelector = (state: RootState) =>
  checkoutSummarySelector(state)?.tickets?.[0]?.id

export const checkoutSummaryUseMarkAsPickedUpSelector = createSelector(
  [
    checkoutSummaryCheckoutOrderFormatSelector,
    checkoutSummaryOutletListItemSelector,
  ],
  (checkoutOrderFormat, checkoutOutlet) => {
    if (checkoutOrderFormat && checkoutOutlet) {
      const orderFormatSetting = checkoutOutlet.orderFormatSettings?.find?.(
        settings => settings.orderFormat === checkoutOrderFormat,
      )

      if (!orderFormatSetting) return false

      return (
        (OrderFormatOption.UseMarkAsPickedUp & orderFormatSetting.options) ===
        OrderFormatOption.UseMarkAsPickedUp
      )
    }

    return false
  },
)

export const checkoutItemsByProductIdSelector = createSelector(
  [checkoutItemsSelector, (_, productId: string) => productId],
  (items, productId) => items.filter(item => item.product.id === productId),
)

export const checkoutItemQuantityByProductIdSelector = createSelector(
  (state: RootState, productId: string) =>
    checkoutItemsByProductIdSelector(state, productId),
  checkoutItems => checkoutItems.reduce((acc, item) => acc + item.quantity, 0),
)

export const checkoutSummaryTicketNumberSelector = createSelector(
  checkoutSummarySelector,
  checkoutSummary => checkoutSummary?.ticketNumber,
)

export const checkoutSummaryOutletNameSelector = createSelector(
  checkoutSummarySelector,
  checkoutSummary => checkoutSummary?.outlet?.name,
)

export const checkoutSummaryOrderIdSelector = createSelector(
  checkoutSummarySelector,
  checkoutSummary => checkoutSummary?.orderId,
)

// Checkout Order Status Selector
export const checkoutOrderStatusStateSelector = createSelector(
  [
    checkoutSummarySelector,
    checkoutSummaryCheckoutPreparationTimeSelector,
    checkoutSummaryCheckoutServiceTimeSelector,
    checkoutSummaryCheckoutStatusSelector,
    checkoutSummaryCustomersFetchTableOrdersSelector,
    checkoutSummaryOutletEnablePartiallyPrepareOrdersSelector,
  ],
  (
    checkoutSummary,
    preparationTime,
    serviceTime,
    checkoutStatus,
    customersFetchTableOrders,
    enablePartiallyPrepareOrders,
  ): OrderSummaryStatusState => ({
    checkout: checkoutSummary,
    preparationTime,
    serviceTime,
    checkoutStatus,
    customersFetchTableOrders,
    enablePartiallyPrepareOrders:
      enablePartiallyPrepareOrders &&
      checkoutSummary?.orderFormat !== OrderFormat.BoxPickup,
  }),
)

export const checkoutCurrentCheckoutMetaDataSelector = createSelector(
  checkoutCurrentCheckoutDetailsSelector,
  checkout => checkout?.metaData,
)

// Checkout Penguin Lockers
export const checkoutCurrentCheckoutPenguinLockersMetaDataSelector =
  createSelector(
    checkoutCurrentCheckoutMetaDataSelector,
    getCheckoutPenguinLockersMetaData,
  )

export const checkoutSummaryPenguinLockersMetaDataSelector = createSelector(
  checkoutSummarySelector,
  checkoutSummary => {
    const penguinLockersMetaData = getCheckoutPenguinLockersMetaData(
      checkoutSummary?.metaData,
    )
    return penguinLockersMetaData
  },
)

export const checkoutIsValidBoxPickupCheckoutSelector = createSelector(
  checkoutCurrentCheckoutPenguinLockersMetaDataSelector,
  metaData =>
    !!metaData?.station?.id && !!metaData?.timeBegin && !!metaData?.expireTime,
)

const generalCheckoutIsValidCheckoutSelector = createSelector(
  [
    checkoutCurrentCheckoutDetailsOrderFormatSelector,
    checkoutFiltersTableNumberSelector,
    checkoutCurrentCheckoutDetailsDeliveryAddressSelector,
    checkoutIsValidBoxPickupCheckoutSelector,
  ],
  (orderFormat, tableNumber, deliveryAddress, isValidBoxPickupCheckout) => {
    switch (orderFormat) {
      case OrderFormat.TableOrder:
        return !!tableNumber
      case OrderFormat.Delivery:
        return !!deliveryAddress
      case OrderFormat.EatIn:
      case OrderFormat.TakeAway:
        return true
      case OrderFormat.BoxPickup:
        return isValidBoxPickupCheckout
      default:
        return false
    }
  },
)

export const currentCheckoutStatusSelector = (state: RootState) =>
  checkoutSelector(state, 'currentCheckoutDetails')?.status

export const generalCheckoutIsWaitingForPaymentSelector = (state: RootState) =>
  currentCheckoutStatusSelector(state) === CheckoutStatus.Received

export const currentCheckoutOutletNameSelector = createSelector(
  [
    isPreOrderCheckoutSelector,
    generalCheckoutOutletNameSelector,
    preOrderCheckoutOutletNameSelector,
  ],
  (isPreOrder, generalCheckoutOutletName, preOrderCheckoutOutletName) =>
    isPreOrder ? preOrderCheckoutOutletName : generalCheckoutOutletName,
)

export const currentCheckoutTotalItemQtySelector = createSelector(
  [
    isPreOrderCheckoutSelector,
    generalCheckoutTotalItemQtySelector,
    preOrderCheckoutTotalItemCountSelector,
  ],
  (isPreOrder, generalCheckoutItemQty, preOrderCheckoutItemQty) =>
    isPreOrder ? preOrderCheckoutItemQty : generalCheckoutItemQty,
)

export const currentCheckoutAddItemPendingSelector = createSelector(
  [
    isPreOrderCheckoutSelector,
    generalCheckoutAddItemPendingSelector,
    preOrderCheckoutAddItemPendingSelector,
  ],
  (
    isPreOrder,
    generalCheckoutAddItemPending,
    preOrderCheckoutAddItemPending,
  ) =>
    isPreOrder ? preOrderCheckoutAddItemPending : generalCheckoutAddItemPending,
)

export const checkoutCurrentCheckoutSelector = createSelector(
  [
    isPreOrderCheckoutSelector,
    checkoutCurrentCheckoutDetailsSelector,
    preOrderCheckoutSelector,
  ],
  (isPreOrder, generalCheckout, preOrderCheckout) =>
    isPreOrder ? preOrderCheckout : generalCheckout,
)

export const currentCheckoutIsValidCheckoutSelector = createSelector(
  [
    isPreOrderCheckoutSelector,
    generalCheckoutIsValidCheckoutSelector,
    preOrderCheckoutIsValidCheckoutSelector,
  ],
  (isPreOrder, isGeneralCheckoutValid, isPreOrderCheckoutValid) =>
    isPreOrder ? isPreOrderCheckoutValid : isGeneralCheckoutValid,
)

export const currentCheckoutIsWaitingForPaymentSelector = createSelector(
  [
    isPreOrderCheckoutSelector,
    generalCheckoutIsWaitingForPaymentSelector,
    preOrderIsWaitingForPaymentSelector,
  ],
  (
    isPreOrder,
    isGeneralCheckoutWaitingForPayment,
    isPreOrderCheckoutWaitingForPayment,
  ) =>
    isPreOrder
      ? isPreOrderCheckoutWaitingForPayment
      : isGeneralCheckoutWaitingForPayment,
)

export const checkoutSummaryDeliveryTrackingURLSelector = createSelector(
  checkoutSummarySelector,
  checkoutSummary => checkoutSummary?.deliveryMetaData?.trackingUrl,
)

export const checkoutSummaryDeliveryStatusSelector = createSelector(
  checkoutSummarySelector,
  checkoutSummary => checkoutSummary?.deliveryStatus,
)

export const checkoutSummaryWoltDeliveryInProgressSelector = createSelector(
  checkoutSummarySelector,
  checkoutSummary => isWoltDeliveryInProgress(checkoutSummary),
)

export const outletSelectedOutletHasCheckoutSelector = createSelector(
  [
    checkoutCurrentCheckoutOutletIdSelector,
    preOrderCheckoutOutletIdSelector,
    outletSelectedOutletIdSelector,
  ],
  (checkoutOutletId, preOrderCheckoutOutletId, outletSelectedOutletId) =>
    (!!checkoutOutletId && checkoutOutletId === outletSelectedOutletId) ||
    (!!preOrderCheckoutOutletId &&
      preOrderCheckoutOutletId === outletSelectedOutletId),
)

export const currentCheckoutTotalAmountCurrencySelector = createSelector(
  [
    isPreOrderCheckoutSelector,
    checkoutCurrentCheckoutTotalAmountCurrencySelector,
    preOrderCheckoutCurrencySelector,
  ],
  (
    isPreOrder,
    generalCheckoutTotalAmountCurrency,
    preOrderCheckoutTotalAmountCurrency,
  ) =>
    isPreOrder
      ? preOrderCheckoutTotalAmountCurrency
      : generalCheckoutTotalAmountCurrency,
)

export const checkoutTippingStateSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutTip')

export const checkoutTipPercentageSelector = (state: RootState) =>
  checkoutTippingStateSelector(state).tipPercentage

export const checkoutTippingVisibleModalTypeSelector = (state: RootState) =>
  checkoutTippingStateSelector(state).visibleModalType

export const checkoutCurrentCheckoutTipAmountSelector = createSelector(
  [
    checkoutCurrentCheckoutDetailsSelector,
    preOrderCheckoutSelector,
    checkoutTippingStateSelector,
    isPreOrderCheckoutSelector,
  ],
  (generalCheckout, preOrderCheckout, tipState, isPreOrderCheckout) => {
    const { tipPercentage, customTipAmount } = tipState
    const checkout = isPreOrderCheckout ? preOrderCheckout : generalCheckout

    if (checkout) {
      if (tipPercentage) {
        const { amount } = getCheckoutGrandTotalAmount(checkout)
        const tipAmount = (amount * tipPercentage) / 100
        return Math.round(tipAmount * 100) / 100
      }
      return customTipAmount
    }

    return null
  },
)

export const checkoutIsTippingAppliedSelector = createSelector(
  checkoutTippingStateSelector,
  tipState => !!tipState.customTipAmount || !!tipState.tipPercentage,
)

export const checkoutIsSkipTipModalSelector = createSelector(
  checkoutTippingStateSelector,
  tipState => tipState.isSkipTipModal,
)

export const checkoutCurrentCheckoutRequestTipSelector = createSelector(
  [
    checkoutFiltersOrderFormatSelector,
    checkoutCurrentCheckoutOutletListItemSelector,
    checkoutIsSkipTipModalSelector,
  ],
  (orderFormat, outletListItem, isSkipTipModal) => {
    if (orderFormat && outletListItem && isSkipTipModal !== true) {
      // Check if AO tipping is enabled
      const isAOTippingEnabled = isAOTippingEnabledForOrderFormat(
        outletListItem,
        orderFormat,
      )

      return isAOTippingEnabled
    }

    return false
  },
)

export const checkoutCurrentCheckoutIsPreOrderSelector = createSelector(
  checkoutCurrentCheckoutSelector,
  checkout => {
    const orderType = checkout?.orderType
    return (
      orderType === CheckoutOrderType.PreOrder ||
      orderType === CheckoutOrderType.PreOrderLink ||
      orderType === CheckoutOrderType.GroupPreOrder
    )
  },
)

// Adyen payment methods

export const checkoutIsFetchingAdyenPaymentMethodsSelector = (
  state: RootState,
) => checkoutSelector(state, 'isFetchingCheckoutAdyenPaymentMethods')

export const checkoutAdyenPaymentMethodsSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutAdyenPaymentMethods')

export const checkoutAdyenPaymentMethodsErrorSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutAdyenPaymentMethodsError')

export const checkoutHasAdyenPaymentMethodsSelector = (state: RootState) =>
  !!checkoutAdyenPaymentMethodsSelector(state)

// Adyen integration

export const checkoutActiveAdyenIntegrationFetchPendingSelector = (
  state: RootState,
) => checkoutSelector(state, 'activeAdyenIntegrationFetchPending')

export const checkoutActiveAdyenIntegrationErrorSelector = (state: RootState) =>
  checkoutSelector(state, 'activeAdyenIntegrationError')

export const checkoutActiveAdyenIntegrationSelector = (state: RootState) =>
  checkoutSelector(state, 'activeAdyenIntegration')

// Adyen drop-in

export const checkoutAdyenDropInAttemptCountSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutAdyenPaymentAttemptCount')
