import { CheckDetails } from '@ancon/wildcat-types'
import getFormattedCurrency from '@ancon/wildcat-utils/currency/getFormattedCurrency'

import { PayAndGoCartSummary } from '../types'
import getFormattedQuantity from '../../app/utils/getFormattedQuantity'

function getDiscountAmount(check: CheckDetails): string | null {
  if (check.subTotalDiscountInclTax.amount > 0) {
    return `-${getFormattedCurrency(
      check.subTotalDiscountInclTax.amount,
      check.subTotalDiscountInclTax.currency,
      { trimTrailingZeros: false },
    )}`
  }

  return null
}

function getTipAmount(tip: number | null, currency: string): string | null {
  if (tip) {
    return getFormattedCurrency(tip, currency, { trimTrailingZeros: false })
  }

  return null
}

function getSubtotalExclTaxAmount(
  check: CheckDetails,
  certainAmount: number | null,
): string {
  if (certainAmount != null) {
    const taxPercentage = check.taxAmount.amount / check.subtotalExclTax.amount

    const subtotalExclTaxAmount = certainAmount / (1 + taxPercentage)

    return getFormattedCurrency(
      subtotalExclTaxAmount,
      check.subtotalExclTax.currency,
      { trimTrailingZeros: false },
    )
  }

  return getFormattedCurrency(
    check.subtotalExclTax.amount,
    check.subtotalExclTax.currency,
    { trimTrailingZeros: false },
  )
}

function getSubtotalInclTaxAmount(
  check: CheckDetails,
  certainAmount: number | null,
): string {
  if (certainAmount != null) {
    return getFormattedCurrency(certainAmount, check.totalAmount.currency, {
      trimTrailingZeros: false,
    })
  }

  return getFormattedCurrency(
    check.subtotalInclTax.amount,
    check.subtotalInclTax.currency,
    { trimTrailingZeros: false },
  )
}

function getTaxAmount(
  check: CheckDetails,
  certainAmount: number | null,
): string {
  if (certainAmount != null) {
    const taxPercentage = check.taxAmount.amount / check.subtotalExclTax.amount

    const subtotalExclTaxAmount = certainAmount / (1 + taxPercentage)

    const taxAmount = Math.max(0, certainAmount - subtotalExclTaxAmount)

    return getFormattedCurrency(taxAmount, check.taxAmount.currency, {
      trimTrailingZeros: false,
    })
  }

  return getFormattedCurrency(
    check.taxAmount.amount,
    check.taxAmount.currency,
    {
      trimTrailingZeros: false,
    },
  )
}

function getTotalAmount(
  check: CheckDetails,
  tipAmount: number | null,
  certainAmount: number | null,
): string {
  const { currency } = check.totalAmount

  const base = certainAmount ?? check.remainingAmountToPay.amount

  const total = base + (tipAmount ?? 0)

  return getFormattedCurrency(total, currency, { trimTrailingZeros: false })
}

function getPaidAmount(check: CheckDetails): string | null {
  const remainingAmount = check.remainingAmountToPay.amount
  const totalAmount = check.totalAmount.amount

  const amount = Math.max(0, totalAmount - remainingAmount)

  if (amount <= 0) {
    return null
  }

  return `-${getFormattedCurrency(amount, check.totalAmount.currency, {
    trimTrailingZeros: false,
  })}`
}

function getPayAndGoCartSummaryFromCheckDetails(
  check: CheckDetails,
  tipInAmount: number | null,
  tipInPercentage: number | null,
  certainAmount: number | null,
): PayAndGoCartSummary {
  const discountAmount = getDiscountAmount(check)

  const subtotalExclTaxAmount = getSubtotalExclTaxAmount(check, certainAmount)

  const subtotalInclTaxAmount = getSubtotalInclTaxAmount(check, certainAmount)

  const taxAmount = getTaxAmount(check, certainAmount)

  const tipAmount = getTipAmount(tipInAmount, check.totalAmount.currency)

  const totalAmount = getTotalAmount(check, tipInAmount, certainAmount)

  const totalItemsCount = getFormattedQuantity(check.items.length)

  const paidAmount = getPaidAmount(check)

  return {
    discountAmount,
    subtotalExclTaxAmount,
    subtotalInclTaxAmount,
    taxAmount,
    tipAmount,
    tipPercentage: tipInPercentage,
    paidAmount,
    totalAmount,
    totalItemsCount,
  }
}

export default getPayAndGoCartSummaryFromCheckDetails
