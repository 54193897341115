import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import omit from 'lodash/omit'
import { SerializedGenericError } from '@ancon/wildcat-utils/error/types'

import getCheckoutFilters from '../utils/getCheckoutFilters'
import { CheckoutErrorContextType, CheckoutUpdateError } from '../types'
import getCheckoutFiltersFromMetaData from '../utils/getCheckoutFiltersFromMetaData'
import {
  cancelPreOrderCheckout,
  fetchPreOrderCheckout,
  preOrderApplyDiscount,
} from '../../preOrder/store/preOrderThunks'
import { preOrderClearCheckout } from '../../preOrder/store/preOrderSlice'

import { checkoutInitialState } from './checkoutInitialState'
import checkoutReducers from './checkoutReducer'
import {
  addCheckoutItem,
  changeCheckoutItemQuantity,
  createCheckout,
  deleteCheckout,
  fetchCheckoutOutletListItem,
  fetchCurrentCheckoutDetails,
  fetchCheckoutSummary,
  fetchCheckoutAdyenPaymentMethods,
  updateCheckout,
  updateCheckoutItem,
  validateCheckout,
  fetchCheckoutWayOfPayments,
  createCheckoutAdyenPayment,
  createInvoicePayment,
  fetchCheckoutStatus,
  fetchActiveAdyenIntegration,
  generalCheckoutApplyDiscount,
  cancelGeneralCheckout,
} from './checkoutThunks'

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: checkoutInitialState,
  reducers: checkoutReducers,
  extraReducers: builder => {
    // Add checkout item
    builder.addCase(addCheckoutItem.pending, state => {
      state.checkoutAddItemPending = true
    })
    builder.addCase(addCheckoutItem.fulfilled, state => {
      state.checkoutAddItemPending = false
    })
    builder.addCase(addCheckoutItem.rejected, state => {
      state.checkoutAddItemPending = false
    })
    // Update checkout item
    builder.addCase(updateCheckoutItem.pending, state => {
      state.checkoutUpdateItemPending = true
    })
    builder.addCase(updateCheckoutItem.fulfilled, state => {
      state.checkoutUpdateItemPending = false
    })
    builder.addCase(updateCheckoutItem.rejected, state => {
      state.checkoutUpdateItemPending = false
    })
    // Fetch current checkout details
    builder.addCase(fetchCurrentCheckoutDetails.pending, state => {
      state.currentCheckoutDetailsFetchPending = true
    })
    builder.addCase(fetchCurrentCheckoutDetails.fulfilled, (state, action) => {
      const { payload } = action
      state.currentCheckoutDetailsFetchPending = false
      state.currentCheckoutDetails = payload
      if (!state.currentCheckoutId) {
        state.currentCheckoutId = payload?.id ?? null
        state.currentCheckoutOutletId = payload?.outlet.id ?? null
      }
      const metaDataFilters = getCheckoutFiltersFromMetaData(payload)
      state.checkoutFilters = getCheckoutFilters(state.checkoutFilters, {
        orderFormat: payload?.orderFormat,
        serviceTime: payload?.serviceTime,
        salesLocation: payload?.salesLocation,
        delivery: payload?.delivery,
        section: metaDataFilters?.section ?? null,
      })
    })
    builder.addCase(fetchCurrentCheckoutDetails.rejected, state => {
      state.currentCheckoutDetailsFetchPending = false
    })
    // Fetch pre-order checkout
    builder.addCase(fetchPreOrderCheckout.fulfilled, (state, action) => {
      const { payload } = action
      if (!state.currentCheckoutId) {
        const { id: preOrderId, outlet } = payload
        state.currentCheckoutId = preOrderId
        state.currentCheckoutOutletId = outlet.id
      }
      state.checkoutFilters = getCheckoutFilters(state.checkoutFilters, {
        orderFormat: payload?.orderFormat,
        serviceTime: payload?.serviceTime,
      })
    })
    // Create checkout
    builder.addCase(createCheckout.fulfilled, (state, action) => {
      const { outletLisItem, checkoutId, checkoutFilters } =
        action.payload || {}
      const { outletId } = action.meta.arg

      state.currentCheckoutOutletListDetails = outletLisItem
      state.currentCheckoutId = checkoutId
      state.currentCheckoutOutletId = outletId

      state.checkoutFilters = getCheckoutFilters(
        state.checkoutFilters,
        checkoutFilters!,
      )
    })
    // Update checkout
    builder.addCase(updateCheckout.pending, state => {
      state.checkoutUpdatePending = true
    })
    builder.addCase(updateCheckout.fulfilled, state => {
      state.checkoutUpdatePending = false
      state.checkoutStockErrorAdditionalData = null
    })
    builder.addCase(updateCheckout.rejected, (state, action) => {
      const { currentCheckoutDetails } = state
      const { checkoutErrorContextType } = action.meta.arg
      const { stockErrorAdditionalData } = action.payload as CheckoutUpdateError

      state.checkoutUpdatePending = false

      if (currentCheckoutDetails) {
        state.checkoutFilters.orderFormat = currentCheckoutDetails.orderFormat
        state.checkoutFilters.serviceTime = currentCheckoutDetails.serviceTime
        state.checkoutFilters.delivery = currentCheckoutDetails.delivery || null
        state.checkoutFilters.salesLocation =
          currentCheckoutDetails.salesLocation

        if (
          !checkoutErrorContextType ||
          checkoutErrorContextType === CheckoutErrorContextType.UpdateCheckout
        ) {
          state.checkoutStockErrorAdditionalData =
            stockErrorAdditionalData || null
        }
      }
    })
    // Change quantity check item
    builder.addCase(changeCheckoutItemQuantity.pending, state => {
      state.checkoutUpdateItemQuantityPending = true
    })
    builder.addCase(changeCheckoutItemQuantity.fulfilled, state => {
      state.checkoutUpdateItemQuantityPending = false
    })
    builder.addCase(changeCheckoutItemQuantity.rejected, state => {
      state.checkoutUpdateItemQuantityPending = false
    })
    // delete checkout
    builder.addCase(deleteCheckout.pending, state => {
      state.checkoutDeletePending = true
    })
    builder.addCase(deleteCheckout.fulfilled, () => checkoutInitialState)
    builder.addCase(deleteCheckout.rejected, state => {
      state.checkoutDeletePending = false
    })
    // fetch checkout outlet listItem
    builder.addCase(fetchCheckoutOutletListItem.pending, state => {
      state.checkoutOutletListItemFetchPending = true
    })
    builder.addCase(fetchCheckoutOutletListItem.fulfilled, (state, action) => {
      const { payload } = action

      state.checkoutOutletListItemFetchPending = false
      state.currentCheckoutOutletListDetails = payload
    })
    builder.addCase(fetchCheckoutOutletListItem.rejected, state => {
      state.checkoutOutletListItemFetchPending = false
    })
    // Fetch checkout summary
    builder.addCase(fetchCheckoutSummary.pending, (state, action) => {
      if (!action.meta.arg.isBackgroundFetch) {
        state.checkoutSummaryFetchPending = true
      }
    })
    builder.addCase(fetchCheckoutSummary.fulfilled, (state, { payload }) => {
      const { checkoutSummary, outlet, timestamp } = payload

      let timestampDateValue = new Date(timestamp).valueOf()

      if (Number.isNaN(timestampDateValue)) {
        timestampDateValue = 0
      }

      // Do not override status if it is not a new one
      if (
        !Number.isNaN(checkoutSummary.status) &&
        timestampDateValue >= state.checkoutStatusLastUpdatedAt
      ) {
        if (state.checkoutSummary) {
          state.checkoutSummary = {
            ...state.checkoutSummary,
            ...omit(checkoutSummary, 'status'),
          }
        } else {
          state.checkoutSummary = checkoutSummary
        }

        state.checkoutStatusLastUpdatedAt = timestampDateValue
      } else {
        state.checkoutSummary = checkoutSummary
      }

      state.checkoutSummaryFetchPending = false
      state.checkoutSummaryOutletListItem = outlet
    })
    builder.addCase(fetchCheckoutSummary.rejected, state => {
      state.checkoutSummaryFetchPending = false
    })
    // Fetch Checkout Way Of Payments
    builder.addCase(fetchCheckoutWayOfPayments.pending, state => {
      state.checkoutWayOfPaymentsFetchPending = true
    })
    builder.addCase(fetchCheckoutWayOfPayments.fulfilled, state => {
      state.checkoutWayOfPaymentsFetchPending = false
    })
    builder.addCase(fetchCheckoutWayOfPayments.rejected, state => {
      state.checkoutWayOfPaymentsFetchPending = false
    })
    // Fetch Adyen payment methods
    builder.addCase(fetchCheckoutAdyenPaymentMethods.pending, state => {
      state.isFetchingCheckoutAdyenPaymentMethods = true
      state.checkoutAdyenPaymentMethodsError = null
    })
    builder.addCase(
      fetchCheckoutAdyenPaymentMethods.fulfilled,
      (state, action) => {
        state.isFetchingCheckoutAdyenPaymentMethods = false
        state.checkoutAdyenPaymentMethods = action.payload
      },
    )
    builder.addCase(
      fetchCheckoutAdyenPaymentMethods.rejected,
      (state, action) => {
        state.isFetchingCheckoutAdyenPaymentMethods = false
        state.checkoutAdyenPaymentMethodsError =
          action.payload as SerializedGenericError
      },
    )
    // Checkout validate
    builder.addCase(validateCheckout.pending, state => {
      state.checkoutValidatePending = true
    })
    builder.addCase(validateCheckout.fulfilled, state => {
      state.checkoutValidatePending = false
    })
    builder.addCase(validateCheckout.rejected, (state, action) => {
      const { stockErrorAdditionalData } = action.payload as CheckoutUpdateError
      const { currentCheckoutDetails } = state

      if (!stockErrorAdditionalData && !currentCheckoutDetails) {
        state.currentCheckoutDetails = null
        state.currentCheckoutId = null
        state.currentCheckoutOutletId = null
      }
      state.checkoutValidatePending = false
    })
    // Status updates from polling
    builder.addCase(fetchCheckoutStatus.fulfilled, (state, action) => {
      const { timestamp, status } = action.payload

      let timestampDateValue = new Date(timestamp || 0).valueOf()

      if (Number.isNaN(timestampDateValue)) {
        timestampDateValue = 0
      }

      const lastUpdatedAtDateValue = new Date(
        state.checkoutStatusLastUpdatedAt || 0,
      ).valueOf()

      if (
        state.currentCheckoutDetails &&
        timestampDateValue >= lastUpdatedAtDateValue
      ) {
        state.currentCheckoutDetails.status = status
        state.checkoutStatusLastUpdatedAt = timestampDateValue
      }

      if (
        state.checkoutSummary &&
        timestampDateValue >= lastUpdatedAtDateValue
      ) {
        state.checkoutSummary.status = status
        state.checkoutStatusLastUpdatedAt = timestampDateValue
      }
    })
    // Active adyen integration
    builder.addCase(fetchActiveAdyenIntegration.pending, state => {
      state.activeAdyenIntegrationFetchPending = true
      state.activeAdyenIntegrationError = null
    })
    builder.addCase(fetchActiveAdyenIntegration.fulfilled, (state, action) => {
      state.activeAdyenIntegrationFetchPending = false
      state.activeAdyenIntegration = action.payload
    })
    builder.addCase(fetchActiveAdyenIntegration.rejected, (state, action) => {
      state.activeAdyenIntegrationFetchPending = false
      state.activeAdyenIntegrationError =
        action.payload as SerializedGenericError
    })
    // Clear current checkout
    builder.addCase(preOrderClearCheckout, state => {
      state.currentCheckoutId = null
      state.currentCheckoutOutletId = null
    })
    // Payment fulfilled
    builder.addCase(createInvoicePayment.fulfilled, state => {
      state.currentCheckoutPaymentPending = false
    })
    builder.addCase(createCheckoutAdyenPayment.fulfilled, state => {
      state.currentCheckoutPaymentPending = false
    })
    // Cancel current checkout
    builder.addMatcher(
      isAnyOf(cancelGeneralCheckout.pending, cancelPreOrderCheckout.pending),
      state => {
        state.checkoutCancelPending = true
      },
    )
    builder.addMatcher(
      isAnyOf(
        cancelGeneralCheckout.fulfilled,
        cancelGeneralCheckout.rejected,
        cancelPreOrderCheckout.fulfilled,
        cancelPreOrderCheckout.rejected,
      ),
      state => {
        state.checkoutCancelPending = false
      },
    )
    // Apply Discount
    builder.addMatcher(
      isAnyOf(
        generalCheckoutApplyDiscount.pending,
        preOrderApplyDiscount.pending,
      ),
      state => {
        state.checkoutApplyDiscountPending = true
      },
    )
    builder.addMatcher(
      isAnyOf(
        generalCheckoutApplyDiscount.fulfilled,
        generalCheckoutApplyDiscount.rejected,
        preOrderApplyDiscount.fulfilled,
        preOrderApplyDiscount.rejected,
      ),
      state => {
        state.checkoutApplyDiscountPending = false
      },
    )
    // Checkout payment
    builder.addMatcher(
      isAnyOf(createCheckoutAdyenPayment.pending, createInvoicePayment.pending),
      state => {
        state.currentCheckoutPaymentPending = true
      },
    )
    builder.addMatcher(
      isAnyOf(
        createCheckoutAdyenPayment.rejected,
        createInvoicePayment.rejected,
      ),
      (state, action) => {
        const { stockErrorAdditionalData } =
          action.payload as CheckoutUpdateError

        state.currentCheckoutPaymentPending = false

        if (stockErrorAdditionalData) {
          state.checkoutStockErrorAdditionalData = stockErrorAdditionalData
        }
      },
    )
  },
})

export default checkoutSlice.reducer

export const {
  setSelectedCheckoutItemId,
  checkoutSetCheckoutFilters,
  checkoutSetIsVisibleMinimumOrderModal,
  clearCurrentCheckout,
  checkoutSetIsVisibleDiscountModal,
  checkoutSetIsVisibleCustomerInfoModal,
  checkoutSetIsVisibleDeleteOrderModal,
  checkoutClearCheckoutSummary,
  checkoutEmitStatusUpdatedEvent,
  checkoutEmitPreptimeUpdatedEvent,
  checkoutItemStatusUpdatedEvent,
  checkoutUpdateCheckoutSummaryStatus,
  checkoutSetIsVisibleBoxPickupScheduleOrderModal,
  checkoutUpdatePenguinLockersMetaData,
  checkoutDeliveryStatusUpdatedEvent,
  checkoutSetTipModalVisibleType,
  checkoutUpdateTipState,
  checkoutRetryAdyenPayment,
  checkoutClearAdyenPaymentAttempts,
} = checkoutSlice.actions
